import React, { useState, useEffect } from 'react'
import { Button, Flex, Card, Row, Col, Statistic, Radio } from 'antd'
import { CoreForm } from '../../components/CoreForm'
import { FormatField } from '../../components/FormatField'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { fetchSiteStatus } from '../../store/slices/settings'

type PeriodType = 'day' | 'week' | 'month' | 'year'

interface StatisticCardProps {
  title: string
  total: number
  new: number
  onClick: () => void
}

const StatisticCard: React.FC<StatisticCardProps> = ({ title, total, new: newCount, onClick }) => (
  <Card hoverable onClick={onClick}>
    <Statistic title={title} value={total} />
    <Statistic title='Новые' value={newCount} />
  </Card>
)

const Statistics: React.FC = () => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const { maintenance, isLoading } = useAppSelector(state => state.settings)
  const [period, setPeriod] = useState<PeriodType>('day')

  useEffect(() => {
    dispatch(fetchSiteStatus())
  }, [dispatch, period])

  // Мок данные для статистики
  const mockData = {
    users: { total: 1000, new: 50 },
    translations: { total: 500, new: 20 },
    transactions: { total: 200, new: 10 },
    balance: { total: 10000, unused: 5000 }
  }

  return (
    <CoreForm
      title={
        <Flex>
          <FormatField value={!maintenance ? 'Активен' : undefined} type='activeTag' />
        </Flex>
      }
      extra={
        <Flex justify='end' gap={8}>
          <Button type='link' onClick={() => nav(-1)}>
            Назад
          </Button>
        </Flex>
      }
    >
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Flex align='center' justify='space-between'>
            <Radio.Group value={period} onChange={e => setPeriod(e.target.value)}>
              <Radio.Button value='day'>День</Radio.Button>
              <Radio.Button value='week'>Неделя</Radio.Button>
              <Radio.Button value='month'>Месяц</Radio.Button>
              <Radio.Button value='year'>Год</Radio.Button>
            </Radio.Group>
          </Flex>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <StatisticCard
            title='Пользователи'
            total={mockData.users.total}
            new={mockData.users.new}
            onClick={() => nav('/admin/user')}
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            title='Переводы'
            total={mockData.translations.total}
            new={mockData.translations.new}
            onClick={() => nav('/admin/translation')}
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            title='Транзакции'
            total={mockData.transactions.total}
            new={mockData.transactions.new}
            onClick={() => nav('/admin/transactions')}
          />
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title='Общий баланс' value={mockData.balance.total} />
            <Statistic title='Неизрасходованный баланс' value={mockData.balance.unused} />
          </Card>
        </Col>
      </Row>
    </CoreForm>
  )
}

export default Statistics
