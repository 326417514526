import React from 'react'
import { Button, Avatar, Dropdown, Col, Row, Typography, Space, theme } from 'antd'
import {
  UserOutlined,
  WalletOutlined,
  LoginOutlined,
  ControlOutlined,
  VideoCameraOutlined,
  FlagOutlined,
  TransactionOutlined,
  MoneyCollectOutlined,
  BarChartOutlined,
  FormOutlined
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { useNavigate, Link } from 'react-router-dom'
import { useAppSelector } from './../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { setUser } from '../../store/slices/user'

const LoginLogout = () => {
  const user: Api.Response.User = useAppSelector((state: any) => state.user.data)
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const { Text } = Typography
  const urls = {
    admin: '/app/profile',
    manager: '/manager/',
    user: '/app/profile'
  }

  const handleLogout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refresh_token')
    dispatch(setUser(null))
    nav('/')
  }
  const { useToken } = theme
  const { token } = useToken()

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
  }

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none'
  }

  const commonItems: MenuProps['items'] = [
    {
      type: 'divider'
    },
    {
      key: '2',
      label: <Link to={urls[user?.role]}>Профиль</Link>,
      icon: <UserOutlined />
    },
    {
      key: '6',
      label: <Link to='/app/list'>Мои видео</Link>,
      icon: <VideoCameraOutlined />
    },
    {
      key: '3',
      label: <Link to='/app/balance'>Баланс</Link>,
      icon: <WalletOutlined />
    },
    {
      type: 'divider'
    },
    {
      key: '5',
      danger: true,
      label: 'Выход',
      onClick: handleLogout,
      icon: <LoginOutlined />
    }
  ]

  const adminItems: MenuProps['items'] = [
    {
      key: '4',
      label: <Link to='/admin/user'>Панель управления</Link>,
      icon: <ControlOutlined />,
      children: [
        { key: '4.1', label: <Link to='/admin/user'>Пользователи</Link>, icon: <UserOutlined /> },
        { key: '4.3', label: <Link to='/admin/translations'>Переводы</Link>, icon: <FlagOutlined /> },
        { key: '4.6', label: <Link to='/admin/promo'>Промокоды</Link>, icon: <MoneyCollectOutlined /> },
        { key: '4.5', label: <Link to='/admin/plan'>Тарифы</Link>, icon: <FormOutlined /> },
        { key: '4.4', label: <Link to='/admin/transactions'>Транзакции</Link>, icon: <TransactionOutlined /> },
        { key: '4.7', label: <Link to='/admin/statistics'>Статистика</Link>, icon: <BarChartOutlined /> }
      ]
    }
  ]

  const items: MenuProps['items'] = user?.role === 'admin' ? [...adminItems, ...commonItems] : commonItems

  return (
    <>
      {!user && (
        <Button type='link' onClick={() => nav('/auth/login')}>
          Войти
        </Button>
      )}
      {user && (
        <>
          <Dropdown
            menu={{ items }}
            placement='bottomRight'
            dropdownRender={menu => (
              <div style={contentStyle}>
                <Space
                  style={{
                    padding: 12,
                    paddingTop: 24,
                    display: 'block',
                    justifyContent: 'center'
                  }}
                >
                  <Button type='primary' block onClick={() => nav('/app/add')}>
                    Перевести
                  </Button>
                </Space>

                {React.cloneElement(menu as React.ReactElement, {
                  style: menuStyle
                })}
              </div>
            )}
          >
            <Button type='link' style={{ display: 'flex', alignItems: 'center' }} className='rowOutlineFix'>
              <Row gutter={[24, 16]} align='middle' justify='center' className='rowOutlineFix'>
                <Col span={4}>
                  <Avatar size={'large'} style={{ textTransform: 'uppercase' }}>
                    {user?.email?.slice(0, 1)}
                  </Avatar>
                </Col>
                <Col span={8}>
                  <Col span={12}>
                    <Text
                      style={{
                        wordBreak: 'normal',
                        whiteSpace: 'nowrap',
                        margin: 0
                      }}
                    >
                      Баланс: {user?.balance}₽
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text type='secondary' style={{ wordBreak: 'normal' }}>
                      {user?.email}
                    </Text>
                  </Col>
                </Col>
              </Row>
            </Button>
          </Dropdown>
        </>
      )}
    </>
  )
}

export default LoginLogout
