import { useState, useEffect } from 'react'
import { CoreForm } from '../../components/CoreForm'
import { message, Tag } from 'antd'
import { TableData } from '../../components/TableData'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { apiGetList, apiDelete, apiUpdate, apiCount } from '../../api/crud'
import { useNavigate } from 'react-router-dom'

const User = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [data, setData] = useState<Api.Response.User[]>()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(10)
  const url = 'user'
  const nav = useNavigate()

  const getData = async (params: Api.Params.Pagination) => {
    setLoading(true)
    const result = await apiGetList(url, params)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      setLoading(false)
      return
    }
    setLoading(false)
    return result.data
  }
  const getTotal = async () => {
    const result = await apiCount(url)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      return
    }
    return result.data
  }

  useEffect(() => {
    const params = {
      offset: (page - 1) * pageSize,
      limit: pageSize
    }
    getData(params).then((data: any) => data && setData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, total])

  useEffect(() => {
    getTotal().then((data: any) => data && setTotal(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: 'Электронная почта',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: (role: 'admin' | 'manager' | 'user') => {
        let color = 'green'
        if (role === 'manager') {
          color = 'geekblue'
        }
        if (role === 'admin') {
          color = 'volcano'
        }
        return <Tag color={color}>{role}</Tag>
      }
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'Последний вход',
      dataIndex: 'last_login',
      key: 'last_login'
    },
    {
      title: 'Источник регистрации',
      dataIndex: 'source',
      key: 'source'
    },
    {
      title: 'Активен',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value: boolean) =>
        value ? (
          <CheckCircleFilled style={{ color: 'green', widows: '24px' }} />
        ) : (
          <CloseCircleFilled style={{ color: 'red', widows: '24px' }} />
        )
    }
  ]

  const handelAdd = () => {
    nav('/admin/user/create')
  }

  const handelDelete = async (ids: Array<any>) => {
    ids.map(async id => {
      const result = await apiDelete(url, id)
      if (result.isError) {
        messageApi.error('При удалении произошла ошибка')
      }
      setTotal(prev => prev - 1)
    })
  }

  const handelBlock = (ids: Array<any>) => {
    ids.map(async id => {
      const result = await apiUpdate(url, id, { is_active: false })
      if (result.isError) {
        messageApi.error('При блокировании пользователя произошла ошибка')
      }
      setTotal(prev => prev - 1)
    })
  }

  const handelUnBlock = (ids: Array<any>) => {
    ids.map(async id => {
      const result = await apiUpdate(url, id, { is_active: true })
      if (result.isError) {
        messageApi.error('При разблокировании пользователя произошла ошибка')
      }
      setTotal(prev => prev - 1)
    })
  }

  const buttons: Array<TableDataType.Button> = [
    {
      title: 'Добавить',
      onClick: handelAdd
    },
    {
      title: 'Удалить',
      danger: true,
      onClickWithSelectedRow: handelDelete
    },
    {
      title: 'Заблокировать',
      onClickWithSelectedRow: handelBlock
    },
    {
      title: 'Разблокировать',
      onClickWithSelectedRow: handelUnBlock
    }
  ]

  return (
    <>
      {contextHolder}
      <CoreForm title='Пользователи'>
        <TableData
          loading={loading}
          items={data}
          columns={columns}
          buttons={buttons}
          routeDetail={url}
          pagination={{ page, pageSize, total, setPage, setPageSize }}
        />
      </CoreForm>
    </>
  )
}

export default User
