import { Button, Form, Input, Flex, message, Alert } from 'antd'
import { apiLogin } from '../../../api/auth'
import { setUser } from '../../../store/slices/user'
import { useAppDispatch } from './../../../hooks/useAppDispatch'
import { useAppSelector } from './../../../hooks/useAppSelector'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { HeaderLogo } from '../../../components/HeaderLogo'
const Login = () => {
  const [form] = Form.useForm()
  const nav = useNavigate()
  const location = useLocation()
  const redirectUrl = new URLSearchParams(location.search).get('redirect_url')
  const isAccountInactive = new URLSearchParams(location.search).get('active') === 'false'
  const dispatch = useAppDispatch()
  const user: Api.Response.User = useAppSelector((state: any) => state.user.data)
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }
  const [messageApi, contextHolder] = message.useMessage()
  const redirectUrls: { admin: string; manager: string; user: string } = {
    admin: '/admin/user',
    manager: '/manger',
    user: '/app/list'
  }

  useEffect(() => {
    if (user) {
      if (redirectUrl) {
        nav(redirectUrl)
      } else {
        nav(redirectUrls[user.role])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    const result = await apiLogin({ username: email, password })
    if (result.isError) {
      console.error(result.data.detail)
      messageApi.error('Проверьте правильность электронной почты и пароля')
    } else {
      localStorage.setItem('accessToken', result.data.access_token)
      localStorage.setItem('refresh_token', result.data.refresh_token)
      dispatch(setUser(result.data.user))
    }
  }

  return (
    <>
      {contextHolder}
      {!isAccountInactive && <HeaderLogo size='big' />}
      {!isAccountInactive && (
        <Form {...layout} form={form} onFinish={handleLogin} size='large'>
          <Form.Item
            label='Электронная почта'
            name='email'
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Пароль'
            name='password'
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.Password />
          </Form.Item>
          <Flex align='center' vertical gap={4}>
            <Button htmlType='submit' type='primary' style={{ width: '100%' }}>
              Войти
            </Button>
            <div>
              <Button htmlType='button' type='link' onClick={() => nav('/auth/reg')}>
                Регистрация
              </Button>
              <Button htmlType='button' type='link' onClick={() => nav('/auth/forget')}>
                Забыл пароль
              </Button>
            </div>

            {/* <Divider>ИЛИ</Divider>
         <Space>
            <YandexLoginButton />
            <VKLoginButton />
          </Space> */}
          </Flex>
        </Form>
      )}
      {isAccountInactive && (
        <Alert
          message='Активируйте аккаунт'
          description='Регистрация прошла успешно! Пожалуйста, активируйте ваш аккаунт, перейдя по ссылке, отправленной на вашу электронную почту.'
          type='warning'
          showIcon
        />
      )}
    </>
  )
}

export default Login
//  TODO: доделать oauth2
