import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { CoreForm } from '../../components/CoreForm'
import { TableData } from '../../components/TableData'
import { FormatField } from '../../components/FormatField'
import { apiCount, apiGetListWithParams } from '../../api/crud'
import { Link } from 'react-router-dom'

interface TranslationData {
  id: string
  userId: string
  videoTitle: string
  videoDuration: number
  targetLanguage: string
  selectedOptions: string[]
  cost: number
  status: string
}

const Translations: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [data, setData] = useState<TranslationData[]>()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(5)

  // Имитация загрузки данных
  const getData = async (params: Api.Params.Pagination) => {
    setLoading(true)
    const result = await apiGetListWithParams('translate', params)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      setLoading(false)
      return
    }
    //@ts-expect-error демо данные
    const enhancedData = result.data.map((item: any) => ({
      ...item,
      cost: Math.floor(Math.random() * 10000) / 100, // Случайная стоимость от 0 до 100
      status: Math.random() > 0.5 ? 'Завершен' : 'В процессе' // Случайный статус
    }))

    setLoading(false)
    return enhancedData
  }
  const getTotal = async () => {
    const result = await apiCount(`translate`, false)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      return
    }
    setTotal(result.data)
  }

  useEffect(() => {
    const params = {
      offset: (page - 1) * pageSize,
      limit: pageSize
    }
    getData(params).then((data: any) => data && setData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, total])

  useEffect(() => {
    getTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log(data)
  const columns = [
    {
      title: 'ID юзера',
      dataIndex: ['owner', 'id'],
      key: 'id',
      render: (id: string) => <Link to={`/admin/user/${id}`}>{id}</Link>
    },
    {
      title: 'Название',
      dataIndex: ['video', 'name'],
      key: 'name'
    },
    {
      title: 'Длина',
      dataIndex: ['video', 'length'],
      key: 'length',
      render: (duration: string) => {
        const durationNumber = parseInt(duration, 10)
        return (
          <FormatField
            value={`${Math.floor(durationNumber / 60)}:${(durationNumber % 60).toString().padStart(2, '0')}`}
            type='text'
          />
        )
      }
    },
    {
      title: 'Язык',
      dataIndex: 'language',
      key: 'language'
    },
    {
      title: 'Опции перевода',
      dataIndex: 'translationOptions',
      key: 'translationOptions',
      render: (_: any, record: any) => {
        const options = []
        if (record.has_logo) options.push('Логотип')
        if (record.subtitle_download) options.push('Скачивание субтитров')
        if (record.subtitle_edit) options.push('Редактирование субтитров')
        if (record.subtitle_on_video) options.push('Субтитры на видео')
        if (record.voice_clone) options.push('Клонирование голоса')
        if (record.lipsync) options.push('Синхронизация губ')
        if (record.save_origin_voice) options.push('Сохранение оригинального голоса')
        if (record.notification) options.push('Уведомление')
        return <FormatField value={options.join(', ')} type='text' />
      }
    },
    {
      title: 'Стоимость (мок)',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost: number) => <FormatField value={cost} type='currency' />
    },
    {
      title: 'Статус (мок)',
      dataIndex: 'status',
      key: 'status',
      render: (value: boolean) => <FormatField value={value} type='bool' />
    }
  ]

  return (
    <>
      {contextHolder}
      <CoreForm title='Статистика переводов'>
        <TableData
          loading={loading}
          items={data}
          columns={columns}
          pagination={{ page, pageSize, total, setPage, setPageSize }}
        />
      </CoreForm>
    </>
  )
}

export default Translations
