import { Layout, Menu, theme } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useNavigate, Outlet } from 'react-router-dom'
import { HeaderLogo } from '../../components/HeaderLogo'
import { LoginLogout } from '../../components/LoginLogout'

const { Header, Content } = Layout
const { useToken } = theme

const Admin = () => {
  const { token } = useToken()
  const nav = useNavigate()

  const items = [
    {
      key: '/admin/user',
      label: 'Пользователи'
    },
    {
      key: '/admin/translations',
      label: 'Переводы'
    },
    {
      key: '/admin/promo',
      label: 'Промокоды'
    },
    {
      key: '/admin/plan',
      label: 'Тарифы'
    },
    {
      key: '/admin/transactions',
      label: 'Транзакции'
    },
    {
      key: '/admin/statistics',
      label: 'Статистика'
    }
  ]

  const handleMenuClick = (data: MenuInfo) => {
    nav(data.key)
  }

  return (
    <Layout style={{ paddingLeft: '10%', paddingRight: '10%', minHeight: '100vh' }}>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: '6px',
          justifyContent: 'space-between',
          backgroundColor: token.colorBgBase,
          borderRadius: token.borderRadiusLG
        }}
      >
        <HeaderLogo />
        <Menu
          mode='horizontal'
          items={items}
          onClick={data => handleMenuClick(data)}
          defaultActiveFirst
          style={{ minWidth: 0, flex: 1, justifyContent: 'center' }}
        />
        <LoginLogout />
      </Header>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  )
}

export default Admin
